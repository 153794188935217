import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <!-- Footer Section Start  --> */}
      <footer id="copyright-sec">
        <div class="container">
          <div class="footer-line">
            <p class="copyright-txt">
              Guruji Online Learning app© Copyright by
              <span class="footer-txt2">
                <Link
                  to="https://1.envato.market/website-portfolio"
                  target="_blank"
                >
                  The_Krishna
                </Link>
              </span>
            </p>
          </div>
        </div>
      </footer>
      {/* <!-- Footer Section End  --> */}
    </>
  );
};
export default Footer;
