import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LightImg1 from "../image/light-1.png";
import LightImg2 from "../image/light-2.png";
import LightImg3 from "../image/light-3.png";
import LightImg4 from "../image/light-4.png";
import DarkImg1 from "../image/dark-1.png";
import DarkImg2 from "../image/dark-2.png";
import DarkImg3 from "../image/dark-3.png";
import DarkImg4 from "../image/dark-4.png";
import Shape1 from "../image/shape1.svg";
import Shape2 from "../image/shape2.svg";
import Shape3 from "../image/shape3.svg";
import Shape4 from "../image/shape4.svg";

const DarkLightScreen = () => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    // infinite: true,
    autoplaySpeed: 1000,
    dots: false,
    arrows: true,
    nextArrow: (
      <div className="slick-custom-arrow slick-custom-arrow-right">
        <i className="fa-solid fa-arrow-right"></i>
      </div>
    ),
    prevArrow: (
      <div className="slick-custom-arrow slick-custom-arrow-left">
        <i className="fa-solid fa-arrow-left"></i>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* <!-- Slider Start  --> */}
      <section className="featured-section">
        <div className="container">
          <div className="featured-top-sec">
            <p>MOBILE SCREEN</p>
            <h2>Dark & Light Mode App Screens</h2>
          </div>
          <div className="inner-bottom-sec">
            <div className="inner-bottom-sec-full">
              <Slider {...settings}>
                <div className="page-section">
                  <div className="item">
                    <div className="layout-mobile label-layout">
                      <img src={LightImg1} alt="page-img" />
                    </div>
                    <div className="volume"></div>
                    <div className="switch"></div>
                  </div>
                </div>
                <div className="page-section">
                  <div className="item">
                    <div className="layout-mobile label-layout">
                      <img src={LightImg2} alt="page-img" />
                    </div>
                    <div className="volume"></div>
                    <div className="switch"></div>
                  </div>
                </div>
                <div className="page-section">
                  <div className="item">
                    <div className="layout-mobile label-layout">
                      <img src={LightImg3} alt="page-img" />
                    </div>
                    <div className="volume"></div>
                    <div className="switch"></div>
                  </div>
                </div>
                <div className="page-section">
                  <div className="item">
                    <div className="layout-mobile label-layout">
                      <img src={LightImg4} alt="page-img" />
                    </div>
                    <div className="volume"></div>
                    <div className="switch"></div>
                  </div>
                </div>
                <div className="page-section">
                  <div className="item">
                    <div className="layout-mobile label-layout">
                      <img src={DarkImg1} alt="page-img" />
                    </div>
                    <div className="volume"></div>
                    <div className="switch"></div>
                  </div>
                </div>
                <div className="page-section">
                  <div className="item">
                    <div className="layout-mobile label-layout">
                      <img src={DarkImg2} alt="page-img" />
                    </div>
                    <div className="volume"></div>
                    <div className="switch"></div>
                  </div>
                </div>
                <div className="page-section">
                  <div className="item">
                    <div className="layout-mobile label-layout">
                      <img src={DarkImg3} alt="page-img" />
                    </div>
                    <div className="volume"></div>
                    <div className="switch"></div>
                  </div>
                </div>
                <div className="page-section">
                  <div className="item">
                    <div className="layout-mobile label-layout">
                      <img src={DarkImg4} alt="page-img" />
                    </div>
                    <div className="volume"></div>
                    <div className="switch"></div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="dots-square2">
          <ul className="shape-parallax-list">
            <li
              className="rt-mouse-parallax shape1 floating"
              id="rt-parallax-instance-3"
            >
              <div data-depth="5.00">
                <img src={Shape1} alt="shape" />
              </div>
            </li>
            <li
              className="rt-mouse-parallax shape2 floating"
              id="rt-parallax-instance-4"
            >
              <div data-depth="5.00">
                <img src={Shape2} alt="shape" />
              </div>
            </li>
            <li
              className="rt-mouse-parallax shape3 floating"
              id="rt-parallax-instance-5"
            >
              <div data-depth="5.00">
                <img src={Shape3} alt="shape" />
              </div>
            </li>
            <li className="shape4">
              <div data-depth="5.00">
                <img src={Shape4} alt="shape" />
              </div>
            </li>
          </ul>
        </div>
      </section>
      {/* <!-- Slider End --> */}
    </>
  );
};
export default DarkLightScreen;
