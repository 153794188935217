import React, { useEffect, useState } from "react";
import CanvasImg from "../image/canvas-1.png";
import BookSvg from "../image/book.svg";
import CapSvg from "../image/cap.svg";
import TextAnimation from "./TextAnimation";
import { Link } from "react-router-dom";

const Home = () => {
  const [activeBtnDs, setActiveBtnDs] = useState("");
  const [activeBtnCs, setActiveBtnCs] = useState("");
  const [activeSize, setActiveSize] = useState("");
  // const [theme, setTheme] = useState("light");

  const handleDsClick = (frameClass) => {
    setActiveBtnDs(frameClass);
    setActiveBtnCs(""); // Reset CS buttons
  };

  const handleCsClick = (frameClass) => {
    setActiveBtnCs(frameClass);
    setActiveBtnDs(""); // Reset DS buttons
  };

  const [theme, setTheme] = useState(() => {
    // Retrieve theme from localStorage if available, default to 'light' if not set
    return localStorage.getItem("theme") || "light";
  });

  useEffect(() => {
    // Update iframe src based on theme change
    if (theme === "light") {
      document.querySelector(".getFrame").src =
        "https://up2client.com/envato/guruji-pwa/main-file/light-mode/onboarding-screen.html";
    } else {
      document.querySelector(".getFrame").src =
        "https://up2client.com/envato/guruji-pwa/main-file/dark-mode/onboarding-screen.html";
    }
    // Save current theme to localStorage
    localStorage.setItem("theme", theme);
  }, [theme]);
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  return (
    <>
      {/* <!-- Preview page Details Start  --> */}
      <section id="preview-page-sec">
        <div className="mobile-app-area" id="particles-snow">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-md-12 col-lg-7 text-lg-left"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
              >
                <h1 className="main-title">
                  Have your dream pwa app
                  <br />
                  in minutes for
                  <TextAnimation />
                </h1>
                <ul className="point-list">
                  <li>PWA Ready</li>
                  <li>Home Screen</li>
                  <li>Sidebar Menu</li>
                  <li>Dark And Light Mode</li>
                  <li>100+ Pages</li>
                  <li>Bootstrap ready</li>
                  <li>Fully Responsive</li>
                  <li>Well Documentation</li>
                </ul>
                <div className="preview-page-btn">
                  <div className=" pre-btn mt-50">
                    <Link to="https://1.envato.market/website-portfolio">
                      Buy Now
                    </Link>
                  </div>
                  <div className="pre-btn pre-btn1 mt-50">
                    <a href="">Click To View</a>
                  </div>
                </div>
                <div className="scan-area">
                  <div className="scan">
                    <img src={CanvasImg} alt="" />
                  </div>
                  <p>
                    Scan to view on <br /> your mobile device
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-5">
                <div className="phoneContent">
                  <div className="ripple-1"></div>
                  <div className="ripple-2"></div>
                  <div className="ripple-3"></div>
                  <div className="ripple-4"></div>
                  <div className="ripple-5"></div>
                  <div className={`volume style-2 ${activeSize}`}></div>
                  <div className={`switch style-2 ${activeSize}`}></div>
                  <div className={`phoneWrapper ${activeBtnDs} ${activeBtnCs}`}>
                    <div className="in">
                      <iframe className="getFrame" src=""></iframe>
                    </div>
                  </div>
                </div>
                <div className="demo-button-section">
                  <div className="demo-button">
                    <div className="demo-text">
                      <p className="title">
                        Check Light &amp; <br /> Dark Demo
                      </p>
                      <svg
                        className="arrow-bottom"
                        width="46"
                        height="59"
                        viewBox="0 0 46 59"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.51966 45.6876L8.36067 32.1066C9.2741 30.5245 11.2969 29.9825 12.879 30.8959C14.4614 31.8104 15.0034 33.8332 14.09 35.4153L11.7067 39.5431C33.8465 34.762 44.1243 16.2885 40.228 1.22C40.0867 0.675368 40.8204 0.360448 41.1236 0.814599C50.5873 15.029 46.2506 37.2289 22.121 47.4985C19.7766 48.4656 17.384 49.3641 14.9543 50.2012L18.6201 52.3177C20.2022 53.2311 20.7445 55.2549 19.8308 56.836C18.9176 58.4191 16.8948 58.9611 15.3124 58.0466L1.73141 50.2056C0.148524 49.2936 -0.393478 47.2708 0.51966 45.6876Z"
                          fill="#EDEDED"
                        ></path>
                      </svg>
                    </div>
                    <div className="hello">
                      <div
                        className={`dz-mode dzMobileDateTheme ${theme}`}
                        onClick={toggleTheme}
                      >
                        <svg
                          className="sun"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 302.4 302.4"
                        >
                          <path
                            d="M204.8,97.6C191.2,84,172,75.2,151.2,75.2s-40,8.4-53.6,22.4c-13.6,13.6-22.4,32.8-22.4,53.6s8.8,40,22.4,53.6
												c13.6,13.6,32.8,22.4,53.6,22.4s40-8.4,53.6-22.4c13.6-13.6,22.4-32.8,22.4-53.6S218.8,111.2,204.8,97.6z M190.4,190.4
												c-10,10-24,16-39.2,16s-29.2-6-39.2-16s-16-24-16-39.2s6-29.2,16-39.2s24-16,39.2-16s29.2,6,39.2,16s16,24,16,39.2
												S200.4,180.4,190.4,190.4z"
                          ></path>
                          <path
                            d="M292,140.8h-30.8c-5.6,0-10.4,4.8-10.4,10.4c0,5.6,4.8,10.4,10.4,10.4H292c5.6,0,10.4-4.8,10.4-10.4
												C302.4,145.6,297.6,140.8,292,140.8z"
                          ></path>
                          <path
                            d="M151.2,250.8c-5.6,0-10.4,4.8-10.4,10.4V292c0,5.6,4.8,10.4,10.4,10.4c5.6,0,10.4-4.8,10.4-10.4v-30.8
												C161.6,255.6,156.8,250.8,151.2,250.8z"
                          ></path>
                          <path d="M258,243.6l-22-22c-3.6-4-10.4-4-14.4,0s-4,10.4,0,14.4l22,22c4,4,10.4,4,14.4,0S262,247.6,258,243.6z"></path>
                          <path
                            d="M151.2,0c-5.6,0-10.4,4.8-10.4,10.4v30.8c0,5.6,4.8,10.4,10.4,10.4c5.6,0,10.4-4.8,10.4-10.4V10.4
												C161.6,4.8,156.8,0,151.2,0z"
                          ></path>
                          <path d="M258.4,44.4c-4-4-10.4-4-14.4,0l-22,22c-4,4-4,10.4,0,14.4c3.6,4,10.4,4,14.4,0l22-22C262.4,54.8,262.4,48.4,258.4,44.4z"></path>
                          <path
                            d="M41.2,140.8H10.4c-5.6,0-10.4,4.8-10.4,10.4s4.4,10.4,10.4,10.4h30.8c5.6,0,10.4-4.8,10.4-10.4
												C51.6,145.6,46.8,140.8,41.2,140.8z"
                          ></path>
                          <path d="M80.4,221.6c-3.6-4-10.4-4-14.4,0l-22,22c-4,4-4,10.4,0,14.4s10.4,4,14.4,0l22-22C84.4,232,84.4,225.6,80.4,221.6z"></path>
                          <path d="M80.4,66.4l-22-22c-4-4-10.4-4-14.4,0s-4,10.4,0,14.4l22,22c4,4,10.4,4,14.4,0S84.4,70.4,80.4,66.4z"></path>
                        </svg>
                        <svg
                          className="moon"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 312.999 312.999"
                        >
                          <path
                            d="M305.6,178.053c-3.2-0.8-6.4,0-9.2,2c-10.4,8.8-22.4,16-35.6,20.8c-12.4,4.8-26,7.2-40.4,7.2c-32.4,0-62-13.2-83.2-34.4
												c-21.2-21.2-34.4-50.8-34.4-83.2c0-13.6,2.4-26.8,6.4-38.8c4.4-12.8,10.8-24.4,19.2-34.4c3.6-4.4,2.8-10.8-1.6-14.4
												c-2.8-2-6-2.8-9.2-2c-34,9.2-63.6,29.6-84.8,56.8c-20.4,26.8-32.8,60-32.8,96.4c0,43.6,17.6,83.2,46.4,112s68.4,46.4,112,46.4
												c36.8,0,70.8-12.8,98-34c27.6-21.6,47.6-52.4,56-87.6C314.4,184.853,311.2,179.253,305.6,178.053z M244.4,261.653
												c-23.2,18.4-52.8,29.6-85.2,29.6c-38,0-72.4-15.6-97.2-40.4c-24.8-24.8-40.4-59.2-40.4-97.2c0-31.6,10.4-60.4,28.4-83.6
												c12.4-16,28-29.2,46-38.4c-2,4.4-4,8.8-5.6,13.6c-5.2,14.4-7.6,29.6-7.6,45.6c0,38,15.6,72.8,40.4,97.6s59.6,40.4,97.6,40.4
												c16.8,0,32.8-2.8,47.6-8.4c5.2-2,10.4-4,15.2-6.4C274,232.453,260.8,248.853,244.4,261.653z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="device-setting-sec">
                  <div className="main-list">
                    <h2 className="d-none">Hidden</h2>
                    <h3 className="d-none">Hidden</h3>
                    <h4 className="title-device">Device Settings</h4>
                    <ul className="size-list">
                      <li className={activeBtnDs === "" ? "active" : ""}>
                        <a
                          className="btn-ds"
                          href=";"
                          data-frame=""
                          onClick={(e) => {
                            e.preventDefault();
                            handleDsClick("");
                          }}
                        >
                          <svg
                            width="24"
                            height="40"
                            viewBox="0 0 24 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="22"
                              height="38"
                              rx="5"
                              fill="white"
                              stroke="#666666"
                              strokeWidth="2"
                            ></rect>
                          </svg>
                          Normal
                        </a>
                      </li>
                      <li
                        className={activeBtnDs === "frame-lg" ? "active" : ""}
                      >
                        <a
                          className="btn-ds"
                          href=";"
                          data-frame="frame-lg"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDsClick("frame-lg");
                          }}
                        >
                          <svg
                            width="30"
                            height="50"
                            viewBox="0 0 30 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="28"
                              height="48"
                              rx="5"
                              fill="white"
                              stroke="#666666"
                              strokeWidth="2"
                            />
                          </svg>
                          Large
                        </a>
                      </li>
                      <li
                        className={
                          activeBtnDs === "frame-rounded" ? "active" : ""
                        }
                      >
                        <a
                          className="btn-ds"
                          href=";"
                          data-frame="frame-rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDsClick("frame-rounded");
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 9.67539V7C1 3.6863 3.68629 1 7 1H9.67539"
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M1 22.3246V25C1 28.3137 3.68629 31 7 31H9.67539"
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M31 9.67539V7C31 3.6863 28.3137 1 25 1H22.3246"
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M31 22.3246V25C31 28.3137 28.3137 31 25 31H22.3246"
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                          Rounded
                        </a>
                      </li>
                      <li
                        className={
                          activeBtnDs === "frame-solid" ? "active" : ""
                        }
                      >
                        <a
                          className="btn-cs"
                          href=";"
                          data-frame="frame-solid"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDsClick("frame-solid");
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 9.67539V1H9.67539"
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M1 22.3246V31H9.67539"
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M31 9.67539V1H22.3246"
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M31 22.3246V31H22.3246"
                              stroke="#666666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                          Squared
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dots-square2">
          <ul className="shape-parallax-list">
            <li
              className="rt-mouse-parallax dots-img1 floating"
              id="rt-parallax-instance-1"
            >
              <div data-depth="5.00">
                <img src={BookSvg} alt="shape" className="" />
              </div>
            </li>
            <li
              className="rt-mouse-parallax yellow-square-frame2 floating"
              id="rt-parallax-instance-2"
            >
              <div data-depth="5.00">
                <img src={CapSvg} alt="shape" className="" />
              </div>
            </li>
          </ul>
        </div>
      </section>
      {/* <!-- Preview page Details End  --> */}
    </>
  );
};
export default Home;
