import React from "react";
import { Link } from "react-router-dom";
import IconTheme from "../image/icon-theme.jpg";
import ShieldSvg from "../image/shield.svg";
import Curly from "../image/curly.png";
const DetailSection = () => {
  return (
    <>
      {/* <!-- Details Section Start --> */}
      <section id="project-detail">
        <div class="container">
          <div class="project-detail-full">
            <div class="project-detail-top">
              <h3 class="d-none">Hidden</h3>
              <div class="categary-box">
                <h4>Reliable and Regular Updates</h4>
                <p>
                  Get a lifetime reliable &amp; regular updates with your
                  purchase.
                </p>
              </div>
              <div class="categary-box">
                <h4>5 Years+ Envato Exclusive Author</h4>
                <p>
                  The_Krishna is a time-tested author with years' experience
                </p>
              </div>
              <div class="categary-box">
                <h4>5-Star Customer Support</h4>
                <p>
                  More than 3000 resolved inqueries and happy customer reviews.
                </p>
              </div>
              <div class="categary-box">
                <h4>Customers Feedback Apporeciative</h4>
                <p>
                  Have a good idea or improvment? It can be ended up in our
                  updates.
                </p>
              </div>
            </div>
            <div class="project-detail-bottom">
              <div class="project-detail-bottom-full">
                <div class="row">
                  <div class="col-xl-3 col-sm-6">
                    <div class="visit-box">
                      <img src={IconTheme} alt="logo" />
                      <h2>Thank You!</h2>
                      <Link
                        to="https://1.envato.market/website-portfolio"
                        class="view-btn"
                        target="_blank"
                      >
                        View Portfolio
                      </Link>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12 order-1 order-xl-0">
                    <div class="title-box">
                      <p class="text-danger mb-1">Purchase now</p>
                      <h3 class="text-white">
                        Get your mobile pwa right now and create your awesome
                        app.
                      </h3>
                    </div>
                    <div class="package-box">
                      <div class="footer-title">
                        Include
                        <br />
                        In
                        <br />
                        Package:
                      </div>
                      <div class="figure">
                        <img src={Curly} />
                      </div>
                      <div class="package-list">
                        <ul class="col">
                          <li>Learning app</li>
                          <li>Premium bundled plugins</li>
                          <li>6 month support included</li>
                        </ul>
                        <ul class="col">
                          <li>Free future app updates</li>
                          <li>Quality checked by Envato</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-6">
                    <div class="guarantee">
                      <img class="mb-3" src={ShieldSvg} alt="img" />
                      <p>Quality Work</p>
                      <h2>100% Guarantee</h2>
                    </div>
                    <Link
                      target="_blank"
                      to="https://1.envato.market/website-portfolio"
                      class="detail-btn  mb-3"
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_37_399)">
                          <path
                            d="M1.25 1.25C0.559644 1.25 0 1.80964 0 2.5C0 3.19036 0.559644 3.75 1.25 3.75H3.47412C3.65612 3.75 3.8335 3.80727 3.98114 3.91368C4.12878 4.0201 4.23919 4.17028 4.29673 4.34294L8.41797 16.709C8.60098 17.258 8.62425 17.8492 8.48389 18.4106L8.14453 19.7705C7.6466 21.7622 9.19698 23.75 11.25 23.75H26.25C26.9404 23.75 27.5 23.1904 27.5 22.5C27.5 21.8096 26.9404 21.25 26.25 21.25H11.25C10.7633 21.25 10.4508 20.8506 10.5688 20.3784L10.8248 19.3563C10.8681 19.1831 10.9681 19.0294 11.1089 18.9195C11.2496 18.8097 11.423 18.75 11.6016 18.75H25C25.5382 18.7503 26.0162 18.4061 26.1865 17.8955L29.519 7.89551C29.789 7.08577 29.1861 6.24961 28.3325 6.25H8.19336C8.01136 6.25 7.83398 6.19274 7.68633 6.08632C7.53869 5.97991 7.42827 5.82973 7.37072 5.65707L6.18652 2.10449C6.01619 1.59394 5.53821 1.24972 5 1.25H1.25ZM10 25C8.61929 25 7.5 26.1193 7.5 27.5C7.5 28.8807 8.61929 30 10 30C11.3807 30 12.5 28.8807 12.5 27.5C12.5 26.1193 11.3807 25 10 25ZM25 25C23.6193 25 22.5 26.1193 22.5 27.5C22.5 28.8807 23.6193 30 25 30C26.3807 30 27.5 28.8807 27.5 27.5C27.5 26.1193 26.3807 25 25 25Z"
                            fill="#fff"
                          ></path>
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="30" height="30" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="text">
                        Regular <br />
                        License
                      </span>
                      <span class="value">$39</span>
                    </Link>
                    <Link
                      target="_blank"
                      to="https://1.envato.market/website-portfolio"
                      class="detail-btn"
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_37_399)">
                          <path
                            d="M1.25 1.25C0.559644 1.25 0 1.80964 0 2.5C0 3.19036 0.559644 3.75 1.25 3.75H3.47412C3.65612 3.75 3.8335 3.80727 3.98114 3.91368C4.12878 4.0201 4.23919 4.17028 4.29673 4.34294L8.41797 16.709C8.60098 17.258 8.62425 17.8492 8.48389 18.4106L8.14453 19.7705C7.6466 21.7622 9.19698 23.75 11.25 23.75H26.25C26.9404 23.75 27.5 23.1904 27.5 22.5C27.5 21.8096 26.9404 21.25 26.25 21.25H11.25C10.7633 21.25 10.4508 20.8506 10.5688 20.3784L10.8248 19.3563C10.8681 19.1831 10.9681 19.0294 11.1089 18.9195C11.2496 18.8097 11.423 18.75 11.6016 18.75H25C25.5382 18.7503 26.0162 18.4061 26.1865 17.8955L29.519 7.89551C29.789 7.08577 29.1861 6.24961 28.3325 6.25H8.19336C8.01136 6.25 7.83398 6.19274 7.68633 6.08632C7.53869 5.97991 7.42827 5.82973 7.37072 5.65707L6.18652 2.10449C6.01619 1.59394 5.53821 1.24972 5 1.25H1.25ZM10 25C8.61929 25 7.5 26.1193 7.5 27.5C7.5 28.8807 8.61929 30 10 30C11.3807 30 12.5 28.8807 12.5 27.5C12.5 26.1193 11.3807 25 10 25ZM25 25C23.6193 25 22.5 26.1193 22.5 27.5C22.5 28.8807 23.6193 30 25 30C26.3807 30 27.5 28.8807 27.5 27.5C27.5 26.1193 26.3807 25 25 25Z"
                            fill="#fff"
                          ></path>
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="30" height="30" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="text">
                        Extended <br />
                        License
                      </span>
                      <span class="value">$750</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Details Section End --> */}
    </>
  );
};
export default DetailSection;
