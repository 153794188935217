import React, { useEffect } from "react";

const TextAnimation = () => {
  const animationDelay = 2500;
  const barAnimationDelay = 3800;
  const barWaiting = barAnimationDelay - 3000;
  const lettersDelay = 50;
  const typeLettersDelay = 150;
  const selectionDuration = 500;
  const typeAnimationDelay = selectionDuration + 800;
  const revealDuration = 600;
  const revealAnimationDelay = 1500;

  useEffect(() => {
    initHeadline();

    function initHeadline() {
      singleLetters(document.querySelectorAll(".cd-headline.letters b"));
      animateHeadline(document.querySelectorAll(".cd-headline"));
    }

    function singleLetters(words) {
      words.forEach((word) => {
        let letters = word.innerText.split("");
        const selected = word.classList.contains("is-visible");
        letters = letters
          .map((letter) => {
            return selected
              ? `<i class="in">${letter}</i>`
              : `<i>${letter}</i>`;
          })
          .join("");
        word.innerHTML = letters;
        word.style.opacity = 1;
      });
    }

    function animateHeadline(headlines) {
      let duration = animationDelay;
      headlines.forEach((headline) => {
        if (headline.classList.contains("loading-bar")) {
          duration = barAnimationDelay;
          setTimeout(() => {
            headline
              .querySelector(".cd-words-wrapper")
              .classList.add("is-loading");
          }, barWaiting);
        } else if (headline.classList.contains("clip")) {
          const spanWrapper = headline.querySelector(".cd-words-wrapper");
          const newWidth = spanWrapper.offsetWidth + 10;
          spanWrapper.style.width = `${newWidth}px`;
        } else if (!headline.classList.contains("type")) {
          const words = headline.querySelectorAll(".cd-words-wrapper b");
          let width = 0;
          words.forEach((word) => {
            const wordWidth = word.offsetWidth;
            if (wordWidth > width) width = wordWidth;
          });
          headline.querySelector(
            ".cd-words-wrapper"
          ).style.width = `${width}px`;
        }

        setTimeout(() => {
          hideWord(headline.querySelector(".is-visible"));
        }, duration);
      });
    }

    function hideWord(word) {
      const nextWord = takeNext(word);
      const parentSpan = word.closest(".cd-words-wrapper");

      if (word.closest(".cd-headline").classList.contains("type")) {
        parentSpan.classList.add("selected");
        parentSpan.classList.remove("waiting");
        setTimeout(() => {
          parentSpan.classList.remove("selected");
          word.classList.remove("is-visible");
          word.classList.add("is-hidden");
          word.querySelectorAll("i").forEach((letter) => {
            letter.classList.remove("in");
            letter.classList.add("out");
          });
        }, selectionDuration);
        setTimeout(() => {
          showWord(nextWord, typeLettersDelay);
        }, typeAnimationDelay);
      } else if (word.closest(".cd-headline").classList.contains("letters")) {
        const bool =
          word.querySelectorAll("i").length >=
          nextWord.querySelectorAll("i").length;
        hideLetter(word.querySelector("i"), word, bool, lettersDelay);
        showLetter(nextWord.querySelector("i"), nextWord, bool, lettersDelay);
      } else if (word.closest(".cd-headline").classList.contains("clip")) {
        parentSpan.style.width = "2px";
        setTimeout(() => {
          switchWord(word, nextWord);
          showWord(nextWord);
        }, revealDuration);
      } else if (
        word.closest(".cd-headline").classList.contains("loading-bar")
      ) {
        parentSpan.classList.remove("is-loading");
        switchWord(word, nextWord);
        setTimeout(() => {
          hideWord(nextWord);
        }, barAnimationDelay);
        setTimeout(() => {
          parentSpan.classList.add("is-loading");
        }, barWaiting);
      } else {
        switchWord(word, nextWord);
        setTimeout(() => {
          hideWord(nextWord);
        }, animationDelay);
      }
    }

    function showWord(word, duration) {
      if (word.closest(".cd-headline").classList.contains("type")) {
        showLetter(word.querySelector("i"), word, false, duration);
        word.classList.add("is-visible");
        word.classList.remove("is-hidden");
      } else if (word.closest(".cd-headline").classList.contains("clip")) {
        const parentSpan = word.closest(".cd-words-wrapper");
        parentSpan.style.width = `${word.offsetWidth + 10}px`;
        setTimeout(() => {
          hideWord(word);
        }, revealAnimationDelay);
      }
    }

    function hideLetter(letter, word, bool, duration) {
      letter.classList.remove("in");
      letter.classList.add("out");

      if (!letter.nextElementSibling) {
        setTimeout(() => {
          hideWord(takeNext(word));
        }, animationDelay);
      } else {
        setTimeout(() => {
          hideLetter(letter.nextElementSibling, word, bool, duration);
        }, duration);
      }

      if (
        letter.is(":last-child") &&
        document.documentElement.classList.contains("no-csstransitions")
      ) {
        const nextWord = takeNext(word);
        switchWord(word, nextWord);
      }
    }

    function showLetter(letter, word, bool, duration) {
      letter.classList.add("in");
      letter.classList.remove("out");

      if (!letter.nextElementSibling) {
        if (word.closest(".cd-headline").classList.contains("type")) {
          setTimeout(() => {
            word.closest(".cd-words-wrapper").classList.add("waiting");
          }, 200);
        }
        if (!bool) {
          setTimeout(() => {
            hideWord(word);
          }, animationDelay);
        }
      } else {
        setTimeout(() => {
          showLetter(letter.nextElementSibling, word, bool, duration);
        }, duration);
      }
    }

    function takeNext(word) {
      return !word.nextElementSibling
        ? word.parentNode.children[0]
        : word.nextElementSibling;
    }

    function takePrev(word) {
      return !word.previousElementSibling
        ? word.parentNode.children[word.parentNode.children.length - 1]
        : word.previousElementSibling;
    }

    function switchWord(oldWord, newWord) {
      oldWord.classList.remove("is-visible");
      oldWord.classList.add("is-hidden");
      newWord.classList.remove("is-hidden");
      newWord.classList.add("is-visible");
    }
  }, []);

  return (
    <span className="cd-headline slide">
      <span className="cd-words-wrapper">
        <b className="theme-color is-visible">Online Course.</b>
        <b className="theme-color is-hidden">Like Udemy.</b>
        <b className="theme-color is-hidden">School.</b>
        <b className="theme-color is-hidden">University.</b>
        <b className="theme-color is-hidden">High School.</b>
        <b className="theme-color is-hidden">Kindergarten.</b>
      </span>
    </span>
  );
};

export default TextAnimation;
