import React from "react";
import ContentImg1 from "../image/content-1.png";
import ContentImg2 from "../image/content-2.png";
import ContentImg3 from "../image/content-3.png";
import ContentImg4 from "../image/content-4.png";
import Shape5 from "../image/shape5.svg";
import Shape6 from "../image/shape6.svg";

const MobileWebsite = () => {
  return (
    <>
      {/* <!-- Mobile Content Start  --> */}
      <section id="mobile-content">
        <ul class="shape-parallax-list">
          <li
            class="rt-mouse-parallax shape5 floating"
            id="rt-parallax-instance-6"
          >
            <div data-depth="5.00">
              <img src={Shape5} alt="shape" />
            </div>
          </li>
          <li
            class="rt-mouse-parallax shape6 floating"
            id="rt-parallax-instance-7"
          >
            <div data-depth="5.00">
              <img src={Shape6} alt="shape" />
            </div>
          </li>
        </ul>
        <div class="container">
          <div class="mobile-content-full">
            <div class="featured-top-sec">
              <p>BUY IT NOW AND START BUILDING YOUR PROJECT</p>
              <h2>Make Mobile Websites & Apps</h2>
            </div>
            <div class="mobile-content-bottom">
              <h3 class="d-none">Hidden</h3>
              <div class="mobile-details">
                <div class="media">
                  <img src={ContentImg1} alt="content-img" class="img-fluid" />
                </div>
                <div class="content-box">
                  <h4>Progressive web app</h4>
                  <p>
                    It is a progressive web app. Add to home screen and use it
                    like an app.
                  </p>
                </div>
              </div>
              <div class="mobile-details">
                <div class="media">
                  <img src={ContentImg2} alt="content-img" class="img-fluid" />
                </div>
                <div class="content-box">
                  <h4>Quality code</h4>
                  <p>
                    Written with a code structure that you can easily
                    understand.
                  </p>
                </div>
              </div>
              <div class="mobile-details">
                <div class="media">
                  <img src={ContentImg3} alt="content-img" class="img-fluid" />
                </div>
                <div class="content-box">
                  <h4>Light and Dark Mode</h4>
                  <p>UI Components designed for the Light and Dark Mode.</p>
                </div>
              </div>
              <div class="mobile-details">
                <div class="media">
                  <img src={ContentImg4} alt="content-img" class="img-fluid" />
                </div>
                <div class="content-box">
                  <h4>Great for phones & tablets</h4>
                  <p>
                    Compatible with all mobile phones and tablet resolutions..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Mobile Content End  --> */}
    </>
  );
};
export default MobileWebsite;
